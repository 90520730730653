.antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

// Border Radius (Rounding)
$rounding-sizes: (
    '': 0.25rem,
    '-md': 0.375rem,
    '-lg': 0.5rem,
);

@each $size, $rem in $rounding-sizes {
    .rounded#{$size} {
        border-radius: $rem;
    }
}

// Borders
.border {
    border-width: 1px;
}

.border-2 {
    border-width: 2px;
}
