.landing-page-background {
    z-index: 106 !important;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
    // backdrop-filter: blur(15px) saturate(160%) contrast(45%) brightness(140%);
    // -webkit-backdrop-filter: blur(15px) saturate(160%) contrast(45%) brightness(140%);

    .video-wrapper {
        height: calc(100% - 3em);
        position: relative;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // display: none;

        &.visible {
            display: inherit;
        }

        video {
            height: 100%;
        }
        .close-button {
            position: absolute;
            right: -1em;
            top: -1em;
        }
    }
}

@media only screen and (max-aspect-ratio: 9/19) {
    .landing-page-background {
        .video-wrapper {
            width: calc(100% - 3em);
            height: unset;
            video {
                width: 100%;
                height: unset;
            }
        }
    }
}
