.custom-form-page {
    z-index: 1002;
    width: 100%;
    height: 100%;
    position: absolute;
    overflow-y: auto;

    .sending-background {
        z-index: +1;
        background-color: #00000088;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
// .custom-form-page {
//     display: flex;
//     flex-direction: column;
//     z-index: 1002;
//     width: 100%;
//     height: 100%;
//     position: absolute;
//     background: white;
//     overflow-y: scroll;

//     .form-image {
//         object-fit: contain;
//         max-width: 100%;
//     }

//     .custom-form-input {
//         //landscape
//         width: 60%;

//         // portrait
//         @media only screen and (max-aspect-ratio: 1/1) {
//             width: 80%;
//         }

//         .MuiFormLabel-root {
//             color: inherit;
//         }
//         .MuiFilledInput-root {
//             background-color: inherit;
//         }
//         .MuiInputBase-root {
//             color: inherit;
//             .MuiInputBase-input {
//                 color: inherit;
//             }
//         }
//     }

//     .custom-form-select {
//         .MuiSelect-icon {
//             color: inherit;
//         }
//     }
//     .custom-form-checkbox {
//         .MuiIconButton-label {
//             color: inherit;
//         }
//     }
//     .sending-background {
//         z-index: +1;
//         background-color: #000000;
//         opacity: 0.5;
//         position: absolute;
//         left: 0;
//         right: 0;
//         top: 0;
//         bottom: 0;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//     }
// }
