.text-xs {
    font-size: 0.75rem /* 12px */;
    line-height: 1rem /* 16px */;
}

.text-sm {
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
}

.text-base {
    font-size: 1rem /* 16px */;
    line-height: 1.5rem /* 24px */;
}
.text-lg {
    font-size: 1.125rem /* 18px */;
    line-height: 1.75rem /* 28px */;
}

.text-xl {
    font-size: 1.25rem /* 20px */;
    line-height: 1.75rem /* 28px */;
}

/* Font Weight */

.font-semibold {
    font-weight: 600;
}

.font-bold {
    font-weight: 700;
}

/* Case */

.uppercase {
    text-transform: uppercase;
}

/* Position */

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}
