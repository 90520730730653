:root {
    --material-icon-style: 'Material Icons';
}

html {
    position: fixed;
    height: 100%;
    width: 100%;
    -webkit-overflow-scrolling: auto;
    overscroll-behavior: none;
    overscroll-behavior-y: none;
}

body {
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    /* old font family, commented in case new font family has any issues */
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
  	"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  	"Helvetica Neue", sans-serif; */
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: fixed;
    overflow: hidden;
    height: 100%;
    width: 100%;
    -webkit-overflow-scrolling: auto;
    overscroll-behavior: none;
    overscroll-behavior-y: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* Menu Headings */
.text-heading {
    font-size: 1.25rem;
    line-height: 1.75rem;
}

/* Tab Headings, Dropdown Headings, Form Headline  */
.text-subheading {
    font-size: 1.125rem;
    line-height: 1.75rem;
}

/* Model Label, Form Inputs, Tabs, Dropdown Items */
.text-body {
    font-size: 1rem;
    line-height: 1.5rem;
}

a-scene > video {
    /*
    this is used to fix a compatibility problem between react-joyride and 8th wall
    8th wall creates a video element (I think this is used so they can get access to the camera feed) on ios this element can be larger than the screen
    when this occurs it changes to boundaries of the screen so when react-joyride displays popups they can display off the bottom and right sides of the screen
    to fix this the maximum height of the video element is set based on the view width and height
    */
    max-width: 100vw;
    max-height: 100vh;
}

.prompt-box-8w {
    z-index: 1302;
}

button,
div {
    /* this is to remove the ghosting effect on buttons more info in below links
        https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-tap-highlight-color
        https://stackoverflow.com/questions/11885161/remove-grey-background-on-link-clicked-in-ios-safari-chrome-firefox
    */
    -webkit-tap-highlight-color: transparent;
}

/*
used for the text in the custom-pinch-scale-component
*/
.custom-pinch-scale-component-scale-text {
    transition: opacity 1s linear;
}

.material-icons {
    font-family: var(--material-icon-style) !important;
}
