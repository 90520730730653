@for $i from 0 to 51 {
    .w-#{$i} {
        width: $i * 0.25rem;
    }

    .h-#{$i} {
        height: $i * 0.25rem;
    }

    .p-#{$i} {
        padding: $i * 0.25rem;
    }

    .py-#{$i} {
        padding-top: $i * 0.25rem;
        padding-bottom: $i * 0.25rem;
    }

    .px-#{$i} {
        padding-left: $i * 0.25rem;
        padding-right: $i * 0.25rem;
    }

    .top-#{$i} {
        top: $i * 0.25rem;
    }

    .left-#{$i} {
        left: $i * 0.25rem;
    }
}

.max-w-md {
    max-width: 28rem /* 448px */;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

@for $i from 0 to 11 {
    .space-y-#{$i} > :not([hidden]) ~ :not([hidden]) {
        margin-top: ($i * 0.25rem);
        margin-bottom: 0;
    }
}
