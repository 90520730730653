.loading-screen {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    background-color: #000000;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 105;
    pointer-events: all;
    padding: 1.25rem;
    background-image: linear-gradient(rgb(70, 71, 102), rgb(45, 46, 67));
    background-size: cover;
    background-position: center center;

    @media (min-width: 720px) and (min-height: 576px) {
        padding: 2.5rem;
    }

    .loading-screen-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: -1;
    }

    .loading-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 1 60%;
        padding: 2.5rem 0;
        width: 100%;

        img {
            flex: 0 0 auto;
            max-width: 75vw;
            max-height: 25vh;
        }
    }
    
    .loading-status {
        flex: 1 1 40%;
        padding: 0 0 2.5rem;

        .progress-circle {
            color: #ffffff;
        }

        .loading-text {
            color: #ffffff;
            display: block;
        }
    }

    /*
    .powered-by-8th-wall {
        position: absolute;
        bottom: 1.25rem;
        right: 1.25rem;
        z-index: 100;  
    }

    .powered-by-viewa {
        position: absolute;
        bottom: 1.25rem;
        left: 1.25rem;
        z-index: 100;
    } */

    .loading-footer {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        gap: 1.25rem;
        width: 100%;
        padding: 0 1.25rem 1.25rem;
        position: absolute;
        bottom: 0;

        @media (min-width: 720px) and (min-height: 576px) {
            padding: 0 2.5rem 2.5rem;
        }

        .powered-by-8th-wall, .powered-by-viewa  {
            flex: 0 0 auto;
            img {
                width: 100%;
            }
        }

        .powered-by-viewa {
            width: 12.5vw;
            img {
                margin: 0 auto 0 0;
            }
        }

        .powered-by-8th-wall {
            width: 36vw;
            img {
                margin: 0 0 0 auto;
            }
        }
    }
}

@-ms-keyframes pulse {
    from {
        transform: scale(0.7);
    }
    to {
        transform: scale(1);
    }
}
@-moz-keyframes pulse {
    from {
        transform: scale(0.7);
    }
    to {
        transform: scale(1);
    }
}
@-webkit-keyframes pulse {
    from {
        transform: scale(0.7);
    }
    to {
        transform: scale(1);
    }
}
@keyframes pulse {
    from {
        transform: scale(0.7);
    }
    to {
        transform: scale(1);
    }
}
