button,
input,
optgroup,
select,
textarea {
    padding: 0;
    line-height: inherit;
    color: inherit;
}

button,
[role='button'] {
    cursor: pointer;
}

button {
    background-color: transparent;
    background-image: none;
}

[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
[multiple],
textarea,
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-width: 1px;
    border-radius: 0px;
    padding-top: 0.5rem;
    padding-right: 0.75rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    line-height: 1.5rem;
}

input[type='text'] {
    &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        text-transform: uppercase;
    }

    &::-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        text-transform: uppercase;
    }

    &::-ms-input-placeholder {
        /* Microsoft Edge */
        text-transform: uppercase;
    }
}

select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 2.5rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    background-color: white;
}

h1 {
    font-weight: 400;
}
