/* Positioning */
.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

/* Flex */

.flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.justify-center {
    justify-content: center;
}

.items-center {
    align-items: center;
}
