.darken-background {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #00000000;
    z-index: 1003;
    overflow: hidden;

    // if you change time time of this value you should also change the animationDuration value in the tsx file
    transition: background-color 1s ease;

    &.panel-open {
        background-color: #00000055;

        .form-submission-confirmation {
            bottom: 0 !important;
        }
    }

    .form-submission-confirmation {
        position: absolute;
        width: 100%;
        height: 16rem;
        z-index: 1004;
        background-color: white;
        bottom: -16.5rem;
        border-radius: 1rem 1rem 0 0;

        // used when no logo is provided
        &.no-logo {
            height: 9rem;
            bottom: -9.5rem;
        }

        // if you change time time of this value you should also change the animationDuration value in the tsx file
        transition: bottom 1s ease;

        .logo-wrapper {
            margin-top: 2rem;
            display: flex;
            justify-content: center;
            .logo {
                height: 5.5rem;
                width: 12.5rem;
            }
        }
        .line-1-wrapper {
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
        .line-2-wrapper {
            margin-bottom: 3rem;
        }
    }
}
